import React from 'react';
import { Link } from 'react-router-dom';

// Styles
import './Header.css';

// Images
import logoImage from './images/logo-small.png';

const Header = () => {

    return (
        <div className="header-wrapper">
            <Link to={`/home`}>
                <img src={logoImage} className="header-logo-image" />
            </Link>
        </div>
    );
};

export default Header;