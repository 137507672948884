import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, onSnapshot, orderBy } from "firebase/firestore";

import './Forms.css';

import MovieList from './MovieList';

import firebaseApp from './firebaseConfig';

const db = getFirestore(firebaseApp);

const Movies = () => {
  const [movies, setMovies] = useState([]);

  console.log("Movies component rendered!");

  // Get the list of movies when the screen loads.
  useEffect(() => {
    // Create a reference to the movies collection
    const moviesCollection = collection(db, 'movies');

    // Create a query to sort movies by title
    const q = query(
      moviesCollection,
      orderBy("title")
    );

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q,
      snapshot => {
        const movieList = snapshot.docs.map(doc => ({
          title: doc.data().title,
          key: doc.data().key,
          genres: doc.data().genres,
          lessons: doc.data().lessons,
        }));

        setMovies(movieList);
      },
      error => {
        console.error("Error fetching movies:", error);
      }
    );

    // Unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleAddMovieClick = () => {
    window.location.href = '/edit?mode=add';
  };
  
  const handleBrowseClick = () => {
    window.location.href = '/browse';
  };
  
  const handleSearchClick = () => {
    window.location.href = '/search';
  };
  
  const handleDatabaseClick = () => {
    window.open('https://console.firebase.google.com/project/mymovies-firebase/firestore/data/~2Factors~2F00fcnjt2iF9I4VWrhl1eF0SOf2TH5CXeIIUHOXFp', '_blank');
  };
  
  const handleBucketClick = () => {
    window.open('https://s3.console.aws.amazon.com/s3/buckets/dadflix?region=us-west-2&tab=objects', '_blank');
  };

  return (
    <div className="movie-list-container">

      {/* BREADCRUMBS */}
      <div className="breadcrumbs">
        /&nbsp; <Link to={`/home`}>Home</Link>&nbsp;
        /&nbsp; Movies
      </div>

      <br />
      <br />

      {/* ADD MOVIE BUTTON */}
      <button type="submit" className="form-button" style={{width: '100px'}} onClick={handleAddMovieClick}>+ Add</button>

      &nbsp;&nbsp;

      {/* BROWSE BUTTON */}
      <button type="submit" className="form-button" style={{width: '100px'}} onClick={handleBrowseClick}>Browse</button>

      &nbsp;&nbsp;

      {/* SEARCH BUTTON */}
      <button type="submit" className="form-button" style={{width: '100px'}} onClick={handleSearchClick}>Search</button>

      &nbsp;&nbsp;

      {/* DATABASE*/}
      <button type="submit" className="form-button" style={{width: '100px'}} onClick={handleDatabaseClick}>Database</button>
      
      &nbsp;&nbsp;

      {/* BUCKET*/}
      <button type="submit" className="form-button" style={{width: '100px'}} onClick={handleBucketClick}>Bucket</button>

      <br />
      <br />

      {/* MOVIE LIST */}
      {movies &&
        <MovieList movies={movies} />
      }

    </div>
  );
};

export default Movies;
