import React from 'react';

// Styles
import './SeriesSummarySmall.css';

function SummarySmall({ series }) {

  return (
    <div className="series-summary-small-container">
      {series && (
        <>
          <div className="series-summary-small-poster-container">
            <img
              src={`https://jupiter-movies.s3.us-west-2.amazonaws.com/${series.key}/Images/poster.jpg`}
              className="series-summary-small-poster-image"
            />
          </div>
        </>
      )}
    </div>
  );
}

export default SummarySmall;

