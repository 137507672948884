import React from 'react';

// Styles
import './SeriesSummaryLarge.css';

function SeriesSummaryLarge({ series }) {

  return (
    <div className="series-summary-large-container">
      {series && (
        <>
          <div className="series-summary-large-poster-container">
            <div className="series-summary-large-gradient-overlay"></div>
              <img
                src={`https://jupiter-movies.s3.us-west-2.amazonaws.com/${series.key}/Images/large.jpg`}
                className="series-summary-large-poster-image"
              />
          </div>

          <div className="series-summary-large-details-overlay">
            <div className="series-summary-large-series-title">
              {series.name}
            </div>
            <div className="series-summary-large-series-synopsis">
              {series.synopsis}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SeriesSummaryLarge;

