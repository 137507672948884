import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// Styles
import './SummarySmall.css';

// Images
import playIcon from './images/play.png';
import replayIcon from './images/replay.png';

function SummarySmall({ movie }) {

  const navigate = useNavigate();

  const handleWatchClick = () => {
    navigate('/play?movieKey=' + movie.key);
  };

  return (
    <div className="summary-small-container">
      {movie && (
        <>
          <div className="summary-small-poster-container">
            <Link to={`/intro?movieKey=${movie.key}`}>
              <img
                src={`https://jupiter-movies.s3.us-west-2.amazonaws.com/${movie.key}/Images/poster.jpg`}
                className="summary-small-poster-image"
              />
            </Link>
          </div>

          <div className="summary-small-details-container">
            <div className="summary-small-movie-title">
              {movie.title}
            </div>
            <div className="summary-small-movie-props">
              {movie.year} • {movie.duration} min • Rated {movie.rating}
              {movie.hdurl != null && movie.hdurl !== "" && " • HD"}
            </div>
            <div className="summary-small-buttons-wrapper">
              <div className="summary-small-button-wrapper" onClick={handleWatchClick}>
                <img src={playIcon} className="summary-small-button" />
                <div className="summary-small-button-text">
                  Play
                </div>
              </div>
              {/*
              <div className="summary-small-button-wrapper">
                <img src={replayIcon} className="summary-small-button" />
                <div className="summary-small-button-text">
                  Restart
                </div>
              </div>
              */}
            </div>
            <div className="summary-small-director">
              Directed by {movie.director}
            </div>
            <div className="summary-small-movie-synopsis">
              {movie.synopsis}
            </div>
            <div className="summary-small-actors">
              {movie.actors}
            </div>
            <div className="summary-small-genres">
              {movie.genres}
            </div>

            <br />
            <br />
            <br />
          </div>
        </>
      )}
    </div>
  );
}

export default SummarySmall;

