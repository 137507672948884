import React, { useState, createContext } from 'react';

export const Cache = createContext();

export const GlobalProvider = ({ children }) => {
  const [movies, setMovies] = useState([]);
  const [movieMap, setMovieMap] = useState({}); // Hash map for quick lookup
  const [remoteRoot] = useState("https://jupiter-movies.s3.us-west-2.amazonaws.com");
  
  





  // Add more state variables as needed
  // const [anotherState, setAnotherState] = useState(initialValue);

  // You can also include functions that manipulate the states here
  const addMovie = movie => {
    setMovies(prevMovies => [...prevMovies, movie]);
  };

  return (
    <Cache.Provider value={{ 
        movies, setMovies, 
        movieMap, setMovieMap, 
        remoteRoot
    }}>
      {children}
    </Cache.Provider>
  );
};
