import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, onSnapshot, orderBy } from "firebase/firestore";
import firebaseApp from './firebaseConfig';

import './SeriesList.css';

function SeriesList({ series }) {
  if (!series) {
    return <div>Loading...</div>;
  }

  return (
    <div className="series-list">
      <div className="series-wrapper">
            <div className="series-name">Series</div>
            <div className="series-items-wrapper">
              {series.map(seriesItem => (
                  <div key={seriesItem.key} className="browse-movie-item">
                    <Link to={`/series?seriesKey=${seriesItem.key}`}>
                      <img
                        src={`https://jupiter-movies.s3.us-west-2.amazonaws.com/${seriesItem.key}/Images/thumbnail.jpg`}
                        alt={`Poster for ${seriesItem.name}`}
                        className="movie-image"
                      />
                    </Link>
                  </div>
              ))}
            </div>
          </div>
    </div>





  );
}

export default SeriesList;
