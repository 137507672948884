import React, { useEffect, useState, useContext } from 'react';
import { getFirestore, collection, query, onSnapshot, where, orderBy } from "firebase/firestore";

import firebaseApp from './firebaseConfig';

// Components
import Header from './Header';
import GenreList from './GenreList';
import SeriesList from './SeriesList';
import SummaryLarge from './SummaryLarge';
import BrowseHeader from './BrowseHeader';

// CSS
import './Browse.css';
import './SummaryLarge.css';

// Cache
import { Cache } from './Cache';

const Browse = () => {
  const { movies, setMovies, setMovieMap  } = useContext(Cache);

  const [series, setSeries] = useState([]);
  const [randomMovie, setRandomMovie] = useState(null);
  const [opacity, setOpacity] = useState(1); // State to control the fade effect
  const [isLoading, setLoading] = useState(false);

  // Firebase database
  const db = getFirestore(firebaseApp);

  // Get the list of movies and series when the screen loads.
  useEffect(() => {

    // SERIES

    // Create a reference to the series collection
    const seriesCollection = collection(db, 'series');

    // Create a query to sort series by title
    const seriesQuery = query(
      seriesCollection
    );

    // Subscribe to real-time updates
    const unsubscribeSeries = onSnapshot(seriesQuery,
      snapshot => {
        const seriesList = snapshot.docs.map(doc => ({
          dateCreated: doc.data().dateCreated,
          dateModified: doc.data().dateModified,
          key: doc.data().key,
          name: doc.data().name
        }));

        setSeries(seriesList);
      },
      error => {
        console.error("Error fetching series:", error);
      }
    );

    // MOVIES

    // Create a reference to the movies collection
    const moviesCollection = collection(db, 'movies');

    // Create a query to sort movies by title
    const q = query(
      moviesCollection,
      where('live', '==', true),
      where('published', '==', true)
    );

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(q,
      snapshot => {
        const movieList = snapshot.docs.map(doc => ({
          dateCreated: doc.data().dateCreated,
          dateModified: doc.data().dateModified,
          dimensions: doc.data().dimensions,
          director: doc.data().director,
          duration: doc.data().duration,
          genres: doc.data().genres,
          height: doc.data().height,
          isEpisode: doc.data().isEpisode,
          key: doc.data().key,
          lastPlayed: doc.data().lastPlayed,
          lastPosition: doc.data().lastPosition,
          live: doc.data().live,
          published: doc.data().published,
          rating: doc.data().rating,
          roles: doc.data().roles,
          synopsis: doc.data().synopsis,
          title: doc.data().title,
          width: doc.data().width,
          year: doc.data().year
        }));

        setMovies(movieList);

        // Create a hash map from the movie list
        const newMovieMap = movieList.reduce((map, movie) => {
          map[movie.key] = movie;
          return map;
        }, {});
        
        setMovieMap(newMovieMap);
        

        // Only set randomMovie if it's not already set
        if (!randomMovie) {
          // Generate a random index
          const randomIndex = Math.floor(Math.random() * movieList.length);

          // Get the random movie title using the random index
          const newRandomMovie = movieList[randomIndex];

          //console.log(newRandomMovie)

          // Set the random movie name in state
          setRandomMovie(newRandomMovie);
        }
      },
      error => {
        console.error("Error fetching movies:", error);
      }
    );

    // Unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (movies.length > 0) {
      let fadeOutTimeout;

      const intervalId = setInterval(() => {
        setOpacity(0); // Start fade out

        fadeOutTimeout = setTimeout(() => {
          const randomIndex = Math.floor(Math.random() * movies.length);
          const newRandomMovie = movies[randomIndex];

          // Indicate that we are now loading the new image
          setLoading(true);

          // Preload the new movie's image
          const img = new Image();
          img.src = `https://jupiter-movies.s3.us-west-2.amazonaws.com/${newRandomMovie.key}/Images/large.jpg`
          img.onload = () => {
            // Image has loaded, set loading to false
            setLoading(false);
            setRandomMovie(newRandomMovie);
            setOpacity(1); // Start fade in
          };
        }, 1000); // This timeout will run 1 second after fading starts
      }, 17000);

      return () => {
        clearInterval(intervalId);
        clearTimeout(fadeOutTimeout);
      };
    }
  }, [movies]);

  return (
    <div>
      
      <Header />

      <div className="summary-fade" style={{ opacity }}>
        {!isLoading && randomMovie && (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
            <div className='browse-header-large'>
              <SummaryLarge movie={randomMovie} />
            </div>
            <div className='browse-header-small'>
              <BrowseHeader movie={randomMovie} />
            </div>


          </div>
        )}
      </div>

      <SeriesList series={series} />
      <GenreList movies={movies} />
    </div>
  );
};

export default Browse;
