import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { UserProvider } from './UserContext';

import './App.css';

import Home from './Home';

import { GlobalProvider } from './Cache';

import Search from './Search';
import Edit from './Edit';
import Intro from './Intro';
import Play from './Play';
import Movies from './Movies';
import Series from './Series';
import Episodes from './Episodes';
import Upcoming from './Upcoming';
import EpisodeDetails from './EpisodeDetails';

function App() {

  return (
    <GlobalProvider>
      <UserProvider>
        <Router>
          <div>
            <Routes>
              {/* Redirect from the root path to /browse */}
              <Route path="/" element={<Navigate replace to="/home" />} />
              <Route path='/home' element={<Home />} />
              <Route path='/edit' element={<Edit />} />
              <Route path='/intro' element={<Intro />} />
              <Route path='/search' element={<Search />} />
              <Route path='/series' element={<Series />} />
              <Route path='/play' element={<Play />} />
              <Route path='/movies' element={<Movies />} />
              <Route path='/episodes' element={<Episodes />} />
              <Route path='/upcoming' element={<Upcoming />} />
              <Route path='/episodedetails' element={<EpisodeDetails />} />
            </Routes>
          </div>
        </Router>
      </UserProvider>
    </GlobalProvider>
  );
}

export default App;

