import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getFirestore, collection, query, onSnapshot, orderBy } from "firebase/firestore";
import firebaseApp from './firebaseConfig';

import './GenreList.css';

function GenreList({ movies }) {
  const [genres, setGenres] = useState({});

  const db = getFirestore(firebaseApp);

  useEffect(() => {
    const genresUnsubscribe = subscribeToGenres();
    return () => {
      genresUnsubscribe();
    };
  }, []);

  const subscribeToGenres = () => {
    const genresCollection = collection(db, 'genres');
    const q = query(genresCollection, orderBy("name"));

    return onSnapshot(q, snapshot => {
      const genresHash = {};
      snapshot.docs.forEach(doc => {
        // Create a hash with the genre key as the key and the name as the value
        genresHash[doc.id] = doc.data().name;
      });

      setGenres(genresHash);
    }, error => {
      console.error("Error fetching genres:", error);
    });
  }

  function groupMoviesByGenre(movies) {
    const groupedMovies = {};

    movies.forEach(movie => {
      console.log(movie)
      if (movie.genres && Array.isArray(movie.genres) && !movie.isEpisode) {
        movie.genres.forEach(genreKey => {
          if (!groupedMovies[genreKey]) {
            groupedMovies[genreKey] = [];
          }
          groupedMovies[genreKey].push(movie);
        });
      }
    });

    return groupedMovies;
  }

  const groupedMoviesByGenre = groupMoviesByGenre(movies);

  return (
    <div className="genre-list">
      {Object.keys(genres).map(genreKey => {
        const hasMovies = groupedMoviesByGenre[genreKey] && groupedMoviesByGenre[genreKey].length > 0;
        if (!hasMovies) {
          return null;
        }

        return (
          <div key={genreKey} className="genre-wrapper">
            <div className="genre-name">{genres[genreKey]}</div>
            <div className="genre-movies-wrapper">
              {groupedMoviesByGenre[genreKey].map(movie => (
                <div key={movie.key} className="browse-movie-item">
                  <Link to={`/intro?movieKey=${movie.key}`}>
                    <img
                      src={`https://jupiter-movies.s3.us-west-2.amazonaws.com/${movie.key}/Images/thumbnail.jpg`}
                      alt={`Poster for ${movie.title}`}
                      className="movie-image"
                    />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default GenreList;
