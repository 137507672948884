import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

import firebaseApp from './firebaseConfig';
import './Play.css';

import VideoPlayer from './VideoPlayer';

// Images
import back from './images/back.png';

const db = getFirestore(firebaseApp);

function Play() {
  const [movie, setMovie] = useState(null);
  const [showBackButton, setShowBackButton] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const movieKey = params.get('movieKey');

  useEffect(() => {
    fetchMovieDetails();
  }, [movieKey]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setShowBackButton(false);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [showBackButton]);

  const fetchMovieDetails = async () => {
    if (!movieKey) return;

    const movieRef = doc(db, 'movies', movieKey);
    const movieSnap = await getDoc(movieRef);
    if (movieSnap.exists()) {
      setMovie(movieSnap.data());
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleVideoPlayerClick = () => {
    setShowBackButton(true);
  };

  return (
    <div className="viewer-container" onMouseOver={handleVideoPlayerClick} onDblClick={handleVideoPlayerClick}>
      {showBackButton && (
        <img src={back} alt="Back" onClick={handleBack} className="back-button" />
      )}

      {movie && (
        <VideoPlayer movie={movie} />
      )}
    </div>
  );
}

export default Play;
