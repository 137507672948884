import React, { useState, useRef, useEffect } from 'react';

import './SeasonMenu.css'; // Update the path if necessary

// Images
import arrowDown from './images/arrow-down.png'; // Update the path if necessary

function SeasonMenu({ seasons, onChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState(seasons.length ? seasons[0].name : '');
  const wrapperRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSeasonClick = (season) => {
    setSelectedSeason(season.name);
    setIsOpen(false);
    onChange(season.key);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef} className="season-menu">
      <div className={`season-menu-selected-value ${isOpen ? 'season-menu-selected-value-open' : ''}`} onClick={toggleDropdown}>
        {selectedSeason}
        <img src={arrowDown} alt="arrow down" className="season-menu-arrow-down"/>
      </div>
      {isOpen && (
        <div className="season-menu-options">
          {seasons.map((season, index) => (
            <div key={season.key} className="season-menu-option" onClick={() => handleSeasonClick(season)}>
              {season.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default SeasonMenu;
