import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

// Cache
import { Cache } from './Cache';

// Styles
import './EpisodeList.css';

function EpisodeList({ episodes, selectedSeasonKey }) {
    // Cached state variables
    const { movieMap } = useContext(Cache);

    // Filter episodes for the selected season
    const filteredEpisodes = episodes.filter(episode => episode.seasonKey === selectedSeasonKey);

    return (
        <div className="episode-list">
            {filteredEpisodes.map(episode => (
                <Link to={`/play?movieKey=${episode.key}`}>
                    <div key={episode.key} className="episode-item">
                        <img
                            src={`https://jupiter-movies.s3.us-west-2.amazonaws.com/${episode.key}/Images/poster.jpg`}
                            className="episode-image"
                        />
                        <div className='episode-name'>
                            {episode.name}
                        </div>
                        <div className='episode-synopsis'>
                            {movieMap[episode.key].synopsis}
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
}

export default EpisodeList;
