import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import firebaseApp from './firebaseConfig'; 

// Styles
import './Intro.css';

// Components
import Header from './Header';
import SummaryLarge from './SummaryLarge';
import SummarySmall from './SummarySmall';

// Images
import back from './images/back.png';

function Intro() {
  const [movie, setMovie] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const movieKey = params.get('movieKey');

  const db = getFirestore(firebaseApp);

  useEffect(() => {
    fetchMovieDetails();
  }, [movieKey]);

  const fetchMovieDetails = async () => {
    if (!movieKey) return;

    const movieRef = doc(db, 'movies', movieKey);
    const movieSnap = await getDoc(movieRef);
    if (movieSnap.exists) {
      setMovie(movieSnap.data());
    }
  };

  const handleBack = () => {
    navigate("/home");
  };

  useEffect(() => {
    if (movie) {
      document.title = `JupiterFilms - ${movie.title}`;
    }
  }, [movie]);

  return (
    <div>
      
    <Header />
    
      <div className="intro-container">
        <img src={back} alt="Back" onClick={handleBack} className="intro-back-button" />
        <div className='intro-large'>
          <SummaryLarge movie={movie} />
        </div>
        <div className='intro-small'>
          <SummarySmall movie={movie} />
        </div>
      
      </div>

    </div>
  );
}

export default Intro;

