import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

// Utilities
import { formatDurationFromSeconds } from './utilities/DateUtilities';

// Styles
import './SummaryLarge.css';

// Images
import watchIcon from './watch.png';

// Cache
import { Cache } from './Cache';

function SummaryLarge({ movie }) {
  const { remoteRoot } = useContext(Cache);

  return (
    <div className="summary-large-container">
      {movie && (
        <>
          <div className="summary-large-poster-container">
            <div className="summary-large-gradient-overlay"></div>
            <Link to={`/intro?movieKey=${movie.key}`}>
              <img
                src={`${remoteRoot}/${movie.key}/Images/large.jpg`}
                className="summary-large-poster-image"
              />
            </Link>
          </div>
          <div className="summary-large-details-overlay">
            <div className="summary-large-movie-title">
              {movie.title}
            </div>
            <div className="summary-large-movie-year">
              {movie.year}
            </div>
            <div className="summary-large-movie-rating">
              {movie.rating}
            </div>
            <div className="summary-large-movie-duration">
              {formatDurationFromSeconds(movie.duration)}
            </div>
            <div className="summary-large-movie-synopsis">
              {movie.synopsis}
            </div>
            <div className="summary-large-button-container">
              <Link to={`/play?movieKey=${movie.key}`}>
                <div className={`summary-large-button-watch`}>
                  <img src={watchIcon} className="summary-large-button-watch-icon" />
                  Watch Now
                </div>
              </Link>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SummaryLarge;

