import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import firebaseApp from './firebaseConfig'; 

// Styles
import './Series.css';

// Components
import Header from './Header';
import SeriesSummaryLarge from './SeriesSummaryLarge';
import SeriesSummarySmall from './SeriesSummarySmall';
import SeasonMenu from './SeasonMenu';
import EpisodeList from './EpisodeList';

// Images
import back from './images/back.png';

function Series() {
  const [series, setSeries] = useState(null);
  const [selectedSeasonKey, setSelectedSeasonKey] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const seriesKey = params.get('seriesKey');

  const db = getFirestore(firebaseApp);

  useEffect(() => {
    fetchSeriesDetails();
  }, [seriesKey]);

  const fetchSeriesDetails = async () => {
    if (!seriesKey) return;
  
    const seriesRef = doc(db, 'series', seriesKey);
    const seriesSnap = await getDoc(seriesRef);
    if (seriesSnap.exists) {
      const seriesData = seriesSnap.data();
      setSeries(seriesData);
  
      // Auto-select the first season if available
      if (seriesData.seasons && seriesData.seasons.length > 0) {
        setSelectedSeasonKey(seriesData.seasons[0].key);
      }
    }
  };
  
  
  const handleSeasonChange = (seasonKey) => {
    setSelectedSeasonKey(seasonKey);
  };

  const handleBack = () => {
    navigate("/home");
  };

  useEffect(() => {
    if (series) {
      document.title = `JupiterFilms - ${series.name}`;
    }
  }, [series]);

  return (
    <div>
      
      <Header />
    
      <div className="series-container">
        <img src={back} alt="Back" onClick={handleBack} className="series-back-button" />
        <div className='series-large'>
          <SeriesSummaryLarge series={series} />
        </div>
        <div className='series-small'>
          <SeriesSummarySmall series={series} />
        </div>

          {series && series.seasons && (
            <div className="series-season-dropdown">
              <SeasonMenu seasons={series.seasons}  onChange={handleSeasonChange} />
            </div>
          )}

          {series && series.episodes && (
            <EpisodeList episodes={series.episodes} selectedSeasonKey={selectedSeasonKey} />
          )}
      
      </div>

    </div>
  );
}

export default Series;

