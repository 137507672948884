import React from 'react';
import { Link } from 'react-router-dom';

import './Forms.css';
import './GenreList.css';

function MovieList({ movies }) {
  return (
    <ul className="movie-list">
      {movies.map(movie => (
        <div key={movie.key} className="movie-item">

          {/* Link to the movie details */}
          {/* <Link to={`/edit?movieKey=${movie.key}`}> */}
            {/* Display movie thumbnail if not null or empty, otherwise poster */}
            <img 
              src={`https://jupiter-movies.s3.us-west-2.amazonaws.com/${movie.key}/Images/thumbnail.jpg`}
              alt={`Poster for ${movie.title}`} 
              className="movie-image"
            />
          {/* </Link> */}
        </div>
      ))}
    </ul>
  );
}

export default MovieList;
